



















































































































































































































































































































































































import Vue from 'vue'
import BalanceCard from '@/components/BalanceCard.vue'
import NoTransactionValidatorAlert from '@/components/NoTransactionValidatorAlert.vue'

import DispatchingFile from '../../../../../store/models/DispatchingFile'
import Dispatching from '../../../../../store/models/Dispatching'
import { mapGetters } from 'vuex'

export default Vue.extend({
  components: {
    BalanceCard,
    NoTransactionValidatorAlert
  },

  data() {
    return {
      loading: false,
      loadingReport: false,
      confirmModalActive: false,
      showFaillureAlert: false,
      items: [],
      hasErrors: false,
      validationFailed: false,
      networkError: false,
      reports: [],
      mtnAmount: 0,
      moovAmount: 0,
      totalAmount: 0,
      creditLine: null,
      columns: [
        { label: '#', field: '_iteration', sortable: false },
        { label: 'Agence du SFD', mapping: 0, field: 'agency' },
        { label: 'Nom complet', mapping: 1, field: 'name' },
        { label: 'Sexe', mapping: 2, field: 'gender' },

        { label: 'NPI', mapping: 3, field: 'npi' },
        {
          label: 'NOM DU BENEFICIAIRE RAVIP',
          mapping: 4,
          field: 'ravipLastname'
        },
        {
          label: 'PRENOMS DU BENEFICIAIRE RAVIP',
          mapping: 5,
          field: 'ravipFirstname'
        },

        { label: 'Département', mapping: 6, field: 'state' },
        { label: 'Commune', mapping: 7, field: 'commune' },
        { label: 'Arrondissement', mapping: 8, field: 'district' },
        { label: 'Village', mapping: 9, field: 'town' },
        { label: 'Numéro mobile', mapping: 10, field: 'phone' },
        { label: 'Groupe de solidarité', mapping: 11, field: 'group' },
        { label: 'Numéro du dossier', mapping: 12, field: 'reference' },
        { label: 'Poste occupé', mapping: 13, field: 'position' },
        { label: "Secteur d'activité", mapping: 14, field: 'activity' },
        { label: 'Montant du crédit', mapping: 15, field: 'amount' },
        {
          label: 'Montant à rembourser en nominal',
          mapping: 16,
          field: 'refundAmount',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Montant à rembourser en interêt',
          mapping: 17,
          field: 'interestAmount',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Montant total à rembourser',
          mapping: 18,
          field: 'totalRefund',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: "Montant de l'échéance",
          mapping: 19,
          field: 'deadlineAmount',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Durée du prêt',
          mapping: 20,
          field: 'loanDuration',
          format: (x: any) => parseInt(x, 10)
        },
        {
          label: 'Différé',
          mapping: 21,
          field: 'deferred',
          format: (x: any) => parseInt(x, 10)
        }
      ],
      isMoovAmountInvalid: false,
      isMtnAmountInvalid: false,
      isCreditLineAmountInvalid: false
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    fileId(this: any) {
      return this.$route.query.fileId
    },
    nbOfbeneficiariesFormat(): string {
      const nb = this.getCurrentProduct.creditDetails.nbOfBeneficiaryPerGroup
      if (nb.length > 0) return nb.join(', ')
      return ''
    }
  },

  created(this: any) {
    if (!this.fileId) {
      this.$notify.error(
        'Une erreur est survenue lors de la génération du rapport. Veuillez recommencer.'
      )
      this.validationFailed = true
      return
    }

    this.loadingReport = true

    this.getReport(this.fileId)
      .then((response: any) => {
        this.loadingReport = false
        this.hasErrors = ['failed', 'invalid'].includes(response.reportStep)
        this.validationFailed = response.reportStep === 'failed'
        this.items = response.items
        this.reports = response.reports
        this.mtnAmount = response.mtnAmount
        this.moovAmount = response.moovAmount
        this.totalAmount = response.totalAmount
        this.creditLine = response.creditLine
        this.isMoovAmountInvalid = this.isOtmAmountInvalid('moov')
        this.isMtnAmountInvalid = this.isOtmAmountInvalid('mtn')
        this.isCreditLineAmountInvalid = this.reports.some(
          (r: any) => r.key === 'CT-DTG-04'
        )
      })
      .catch((error: any) => {
        this.loadingReport = false
        this.networkError = /Network Error/.test(error.message)
        this.validationFailed = !this.networkError
      })
  },

  methods: {
    getReport(id: string) {
      return new Promise((resolve, reject) => {
        const timer = setInterval(async () => {
          try {
            const res = await DispatchingFile.api().report(id)
            console.log(res.response.data)
            if (res.response.data.reviewed) {
              clearInterval(timer)
              resolve(res.response.data)
            }
          } catch (error) {
            reject(error)
          }
        }, 2000)
      })
    },

    onCreateDispatchingClicked(this: any) {
      this.loading = true
      this.showFaillureAlert = false
      Dispatching.api()
        .initiate(this.fileId)
        .then(() => {
          this.loading = false
          this.confirmModalActive = true
        })
        .catch((error: any) => {
          this.loading = false
          if (error.response && error.response.status === 424) {
            this.showFaillureAlert = true
          }
        })
    },

    isInvalidWalletAccount(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-02')
      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    isOtmAmountInvalid(this: any, otm: string) {
      const report = this.reports.find((r: any) => r.key === 'CT-DTG-06')

      if (!report || !report.otms) {
        return false
      }

      return report.otms.includes(otm)
    },

    alreadyInDispatching(this: any, index: number) {
      const report = this.reports.find((r: any) => r.key === 'CT-DTG-03')
      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    isPhoneDuplicated(this: any, index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-07')

      if (report && report.rows) {
        return report.rows[index]
      }
    },

    isInvalidIdentity(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-01')

      if (report && report.rows) {
        return report.rows[index]
      }
    },

    isInvalidProfile(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-09')

      if (report && report.rows) {
        return report.rows.includes(index)
      }
    },

    isMoovUnauthorizedAccount(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-23')

      if (report && report.rows) {
        return report.rows.includes(index)
      }
    },

    isLineAmountInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-05')
      return report && report.rows[index]
    },

    isGroupLengthInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-08')
      return report && report.rows.includes(index)
    },

    hasInvalidDeadlines(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-11')
      return report && report.rows[index]
    },

    hasSimilarBeneficiary(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-12')
      return report && report.rows && report.rows[index]
    },

    hasDuplicateName(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-13')
      return report && report.rows && report.rows[index]
    },

    profileCheckFailed(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-10')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    loanDurationInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-14')
      return report && report.rows && report.rows[index]
    },

    differedDurationInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-15')
      return report && report.rows && report.rows[index]
    },

    differedGreaterThanLoanDuration(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-17')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    sameLoanDurationForGroup(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-18')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    sameDefferedForGroup(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-19')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    npiCheckFailed(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-22')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    otherVersionDebtCheckFailed(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-24')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    npiInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-21')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    npiInfosInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-20')
      return report && report.rows && report.rows[index]
    },

    moovUnauthorizeNumber(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-DTG-23')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    rowClass(item: any, i: number) {
      const hasErrors = this.reports.some((report: any) => {
        if (!report.rows) {
          return false
        }
        return Array.isArray(report.rows)
          ? report.rows.includes(i)
          : Object.keys(report.rows).includes(String(i))
      })
      return hasErrors ? 'bg-red-100' : ''
    },

    activitySectorInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-AS-1')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },
    genderInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-AS-2')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },
    stateInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-LI-1')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    communeInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-LI-2')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    communeInStateInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-LI-3')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    districtInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-LI-4')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },

    villageInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-LI-5')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },
    positionInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-PS-01')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    },
    presidentPositionInvalid(index: number) {
      const report: any = this.reports.find((r: any) => r.key === 'CT-PS-02')

      if (!report || !report.rows) {
        return false
      }

      return report.rows.includes(index)
    }
  }
})
